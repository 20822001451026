.section-menus {
  @include media("<desktop") {
    .cols .col--50 {
      flex: 0;
      border-top: 0 !important;

      &:first-child {
        padding-bottom: 0;
      }

      &:last-child {
        padding-top: 0;
      }
    }
  }
}

.section-menus__title {
  @include media(">=desktop") {
    span {
      display: block;
    }
  }

  @include media(">=desktop") {
    margin-bottom: #{$line-height}em;
  }
}

.section-menus__text {
  @include media(">=desktop") {
    padding-left: 51px;
  }
}

.section-menus__download {
  display: block;
  text-transform: uppercase;

  &:not(:last-child) {
    @include media(">=tablet") {
      margin-bottom: #{$line-height}em;
    }

    @include media("<tablet") {
      margin-bottom: #{$line-height-sm}em;
    }
  }

  a {
    display: inline-block;
    &:hover {
      .t-red {
        color: #000;
      }
    }
  }
}