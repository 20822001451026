.popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  @include media("<tablet") {
    &:not(.popup-wrap--discrete) {
      background-color: #fff;
    }
  }

  &:not(.is-active) {
    visibility: hidden;
    pointer-events: none;
  }
}

.popup-inner {
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  flex: 0 0 auto;
  -webkit-overflow-scrolling: touch;
  
  @include media(">=tablet") {
    padding: 0 $margin-sm-regular;
  }
}

.popup {
  padding: ($margin-sm-regular * 3) $margin-sm-regular $margin-sm-regular;

  @include media(">=tablet") {
    position: relative;
    padding: ($margin-lg-regular * 2) ($margin-lg-regular * 2);
    background-color: #fff;
    border: 1px solid;
    margin: $margin-sm-regular 0;
  }
}


.popup-wrap--discrete {
  .popup-inner {
    @include media("<tablet") {
      padding: 0 $margin-sm-regular;
    }
  }

  .popup {
    @include media("<tablet") {
      position: relative;
      background-color: #fff;
      border: 1px solid;
    }
  }
}
.popup-close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 0;
  height: 66px;
  width: 66px;

  @include media(">=tablet") {
    height: 60px;
    width: 60px;
  }

  svg {
    display: block;
    width: 100%;
    height: 16px;
  }
}

.popup-body {
  text-align: center;
}