.page-not-found {
  text-align: center;

  .page-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-height: calc(100vh - #{$header-height});
    justify-content: center;

    @include media("<tablet") {
      min-height: calc(100vh - #{$header-md-height});
    }

    @include media("<small-phone") {
      min-height: calc(100vh - #{$header-sm-height});
    }
  }
}