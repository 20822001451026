*, *:before, *:after {
  box-sizing: border-box;
}

body {
  width: 100vw;
  overflow-x: hidden;
  background-color: #fff;

  &.is-locked {
    overflow: hidden;
  }
}

.content {
  position: relative;
  z-index: 1;
  overflow-x: hidden;

  &:focus {
    outline: 0;
  }
}

.site-header,
.site-footer {
  flex: 0 0 auto;
}