.section-events__icon {
  width: 103px;
  height: 115px;
  
  @include media(">=small-desktop") {
    width: 120px;
    height: 134px;  
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}