.img-thumb {
  border: 1px solid #000;
  width: 200px;
}

.img-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.img-frame {
  flex: 1 1 auto;
  position: relative;
  min-height: 300px;

  @include media("<desktop") {
    height: 65vw;
    min-height: 200px;
  }
}

.img-block {
  display: block;
  width: 100%;
  height: auto;
}