.newsletter-signup {
  @include media(">=desktop") {
    &:not(:only-child) {
      margin-top: #{$line-height * 2}em;
    }
  }
}

.booking-widget.wide-spacing {
  @include media("<desktop") {
    margin: -0.1333335em 0;
  }
}

.reservations-button {
  cursor: pointer;
  color: $color-red;

  &:hover {
    color: #000;
  }
}

.section-reservations__title {
  text-transform: uppercase;
  margin-bottom: #{$line-height}em;
}