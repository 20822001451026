.section-chic-hen__icon {
  width: 103px;
  height: 143px;
  
  @include media(">=desktop") {
    width: 140px;
    height: 194px;  
  }
  
  @include media(">=small-desktop") {
    width: 200px;
    height: 278px;  
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.section-chic-hen__book {
  display: inline-block;

  @media (hover: hover) {
    &:hover {
      color: #000;
    }
  }

  @include media(">=tablet") {
    margin-top: #{$line-height-sm}em;
  }

  @include media("<tablet") {
    margin-top: #{$line-height}em;
  }
}