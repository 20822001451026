.section-groups__title {
  text-transform: uppercase;
  letter-spacing: 0.3em;

  @include media(">=tablet", "<desktop") {
    margin-bottom: #{$line-height}em;
  }

  @include media(">=small-desktop") {
    margin-bottom: #{$line-height * 3}em;
    margin-left: -63px;
  }
}

.section-groups__menus {
  @include media(">=tablet") {
    margin-top: #{$line-height}em;
  }

  @include media("<tablet") {
    margin-top: #{$line-height-sm}em;
  }
}

.section-groups__menu {
  display: block;
  text-transform: uppercase;

  a {
    display: inline-block;
    &:hover {
      .t-red {
        color: #000;
      }
    }
  }
}