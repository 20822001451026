.section-jobs__text,
.section-jobs__thumb {
  @include media(">=tablet") {
    margin: #{$line-height-sm}em 0;
  }

  @include media("<tablet") {
    margin: #{$line-height}em 0;
  }
}

.section-jobs__list {
  a {
    color: $color-red;

    &:hover {
      color: #000;
    }
  }

  // @include media(">=tablet") {
  //   margin-top: #{$line-height}em;
  // }

  // @include media("<tablet") {
  //   margin-top: #{$line-height-sm}em;
  // }
}

.section-jobs__content {
  @include media(">=desktop") {
    width: 100%;
    max-width: 440px;
    margin-left: auto;
  }
}

.section-jobs__title {
  @include media(">=desktop") {
    margin-bottom: #{$line-height}em;
  }
}