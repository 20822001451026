::placeholder {
  color: #80807F;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.input-button {
  width: 100%;
  text-transform: uppercase;
  color: $color-red;
  text-align: left;
  border-top: 1px solid #000;
  margin-top: #{$line-height-sm / 2}em;
  margin-top: calc(#{$line-height-sm / 2}em - 1px);
  padding-top: #{$line-height-sm / 2}em;

  @include media(">=tablet") {
    margin-top: #{$line-height / 2}em;
    margin-top: calc(#{$line-height / 2}em - 1px);
    padding-top: #{$line-height / 2}em;
  }

  &[disabled]{
    cursor: wait;
  }

  &:not([disabled]){
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }
}

form.wide-spacing {
  @include media("<desktop") {
    line-height: 1.6;
  }
}

.form-label {
  margin-right: 10px;
}

.form-row {
  display: flex;
  justify-content: space-between;

  .input-text,
  .input-select-wrap{
    display: inline-block;
    flex: 1 0 auto;
    width: 65%;

    @include media("<small-phone") {
      width: 60%;
    }
  }

  .form-label {
    width: 35%;
    flex: 0 1 auto;

    @include media("<small-phone") {
      width: 40%;
    }
  }
}

.booking-widget {
  .input-text,
  .input-select-wrap{
    width: 55%;

    @include media("<small-phone") {
      width: 60%;
    }
  }

  .form-label {
    width: 45%;

    @include media("<small-phone") {
      width: 40%;
    }
  }
}

.form-label {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  overflow: hidden;
  overflow-wrap: break-word;

  span {
    display: block;
    flex: 0 0 auto;
  }

  &:after {
    content: '................................................................';
    flex: 1 1 auto;
    height: 1px;
    width: 10px;
    display: inline-block;
    overflow-wrap: break-word;
    text-align: right;
  }
}

.input-select-wrap {
  position: relative;
  display: inline-flex;
  flex: 1 0 auto;

  input, select {
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  .input-select,
  .calendar-date-holder {
    padding-right: 24px;
  }
}

.input-select-wrap {
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    z-index: 0;
    border-style: solid;
    border-width: 9px 8px 0 8px;
    border-color: #000000 transparent transparent transparent;
    transform: translateY(-50%);
  }
}

.input-select-wrap {
  &:after {
    right: 0;

    @include media("<tablet") {
      margin-left: .25em;
      margin-top: -.125em;
    }

    @include media(">=tablet") {
      margin-left: .25em;
      margin-top: -.175em;
    }
  }
}

form {
  position: relative;
}

.form-message {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

@include media("<desktop") {
  .col--50.enquiry-form-wrap {
    padding: 0;
    overflow: hidden;
    margin-right: $margin-sm-regular;
    margin-left: $margin-sm-regular;

    @include media(">=tablet") {
      margin-right: $margin-lg-regular;
      margin-left: $margin-lg-regular;
    }

    .form-wrap,
    .form-message {
      padding: ($margin-sm-regular - 3px) 0 ($margin-sm-regular - 8px);

      @include media(">=tablet") {
        padding: ($margin-lg-regular - 5px) 0 ($margin-lg-regular - 10px);
      }
    }

    .form-wrap,
    .form-message {
      padding-top: 0;
    }
  }
}

.enquiry-form-expand {
  position: relative;
  text-align: left;
  color: $color-red;
  cursor: pointer;
  display: inline-block;

  @include media(">=tablet") {
    margin-top: #{$line-height-sm}em;
  }

  @include media("<tablet") {
    margin-top: #{$line-height}em;
  }

  &:hover {
    color: #000;
  }
}

.addressFormVerify {
  display: none !important;
}