.site-footer {
  position: sticky;
  bottom: 0;
  z-index: 100;
  background-color: #fff;
  border-top: 1px solid #000;
  padding: 12px $margin-sm-regular 9px;
  display: flex;
  font-size: $font-small;
  line-height: 1;

  @include media(">=desktop") {
    justify-content: flex-end;
  }

  @include media(">=tablet") {
    padding: 12px $margin-lg-regular 9px;
  }

  @include media("<tablet") {
    font-size: $font-sm-small;
  }
}

.site-footer__nav {
  display: inline-block;
  
  a:not(.t-red) {
    &:hover {
      color: $color-red;
    }
  }

  a.t-red {
    &:hover {
      color: #000;
    }
  }

  ul {
    display: flex; 
  }

  li {
    &:not(:first-child) {
      margin: 0 0 0 10px;
    }

    &:not(:first-child):before {
      content: ':';
      margin: 0 10px 0 0;
    }
  }
}