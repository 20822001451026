.site-header {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  line-height: 1;
  user-select: none;

  @media (hover:hover) {
    &:hover .site-title {
      span:nth-child(odd) {
        transform: translateY(-8px)
      }
      span:nth-child(even) {
        transform: translateY(8px)
      }
    }
  }
}

.site-header__bar {
  position: relative;
  z-index: 10;
  background: #fff;
  border-bottom: 1px solid #000;

  @include media(">=tablet") {
    display: flex;
    justify-content: space-between;
  }

  @include media(">=tablet") {
    padding: $margin-lg-regular $margin-lg-regular ($margin-lg-regular - 5px);
  }

  @include media("<tablet") {
    padding: 14px 13px 10px;
  }

  &.site-header__bar--standalone {
    width: 100%;
    display: flex;
    justify-content: center;

    .site-title {
      line-height: 1;
    }
  }
}

.site-header__spacer,
.site-header__location {
  flex: 1 1 33.33%;
}

.site-header__spacer {
  @include media("<small-desktop") {
    display: none;
  }
}

.site-header__location {
  text-align: right;

  @include media("<tablet") {
    margin-top: 5px;
  }

  a {
    &:hover {
      color: $color-red;
    }
  }
}

.site-title {
  text-transform: uppercase;
  letter-spacing: 0.48em;
  cursor: pointer;
  margin-right: -0.48em;

  @include media("<tablet") {
    text-align: right;
  }

  @include media("<small-desktop") {
    .site-header__bar:not(.site-header__bar--standalone) & {
      margin-left: 21px + (13px * 2);
    }
  }

  span {
    display: inline-block;
    transition: all 350ms $easeOutBack;
  }
}

@include media(">=tablet") {
  .is-nav-active {
    .site-title {
      span:nth-child(odd) {
        transform: translateY(-8px)
      }
      span:nth-child(even) {
        transform: translateY(8px)
      }
    }
  }
}

@include media("<tablet") {
  .is-nav-active {
    .site-title {
      span:nth-child(odd) {
        transform: translateY(-2px)
      }
      span:nth-child(even) {
        transform: translateY(2px)
      }
    }
  }
}

.site-nav {
  position: absolute;
  left: 0;
  z-index: 5;
  background-color: #fff;
  border-bottom: 1px solid #000;
  width: 100%;
  overflow-y: auto;
  display: none;
  line-height: 1.35;

  @include media("<tablet") {
    text-align: right;
  }

  ul {
    text-transform: uppercase;
  }

  .is-nav-active &{
    display: block;

    @include media(">=tablet") {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  @media (hover:hover) {
    ul a:hover {
      color: $color-red;
    }
  }

  @include media("<small-phone") {
    top: $header-sm-height;
    max-height: calc(100vh - #{$header-sm-height} - #{$footer-sm-height} + 2px);
    padding: 14px 13px 8px;
  }

  @include media(">=small-phone", "<tablet") {
    top: $header-md-height;
    max-height: calc(100vh - #{$header-md-height} - #{$footer-sm-height} + 2px);
    padding: 14px 13px 8px;
  }

  @include media(">=tablet") {
    top: $header-height;
    max-height: calc(100vh - #{$header-height} - #{$footer-height} + 2px);
    padding: ($margin-lg-regular - 4px) $margin-lg-regular ($margin-lg-regular - 9px);
  }
}

.site-nav__location {
  text-align: right;

  @include media("<tablet") {
    margin-top: 1.35em;
  }

  a {
    display: block;

    &:hover {
      .highlight {
        color: $color-red;
      }
    }
  }

  .highlight {
    text-transform: uppercase;
  }
}

.site-header__icon {
  height: 100%;
  cursor: pointer;
  position: relative;
  z-index: 1;
  position: absolute;
  padding: 0 13px;
  left: 0;
  top: 0;
  border-radius: 0;
  width: 21px + (13px * 2);

  @include media("<tablet") {
    padding-top: 1px;
  }

  @include media(">=tablet") {
    padding: 0 $margin-lg-regular;
    width: 21px + ($margin-lg-regular * 2);
  }
}

.nav-open,
.nav-close {
  position: absolute;
  // top: 50%;
  left: 0;
  width: 100%;
  height: 16px;
  transition: opacity 350ms;
  display: block;

  @include media("<tablet") {
    top: 14px;
  }

  @include media(">=tablet") {
    transform: translateY(-50%);
    top: 38px;
  }
}

.nav-close {
  opacity: 0;
}

.is-nav-active {
  .nav-close {
    opacity: 1;
  }

  .nav-open {
    opacity: 0;
  }
}