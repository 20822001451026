$breakpoints: ('small-phone': 375px, 'phone': 568px, 'tablet': 768px, 'desktop': 860px, 'small-desktop': 1024px, 'mid-desktop': 1280px, 'large-desktop': 1800px);

$font-stack: "Bistrotheque Modern", "Times New Roman", serif;

$line-height-sm: 1.3333333333;
$line-height: 1.2;

$font-sm-small: 0.8125em;
$font-small: 0.8em;

$font-sm-regular: 16.25px;
$font-md-regular: 19px;
$font-regular: 20px;

$margin-sm-regular: 22px;
$margin-lg-regular: 30px;

$color-red: red;

$header-sm-height: 62px;
$footer-sm-height: 35px;

$header-md-height: 68px;
$footer-md-height: 37px;

$header-height: 76px;
$footer-height: 38px;