html {
  font-family: $font-stack;
  font-size: $font-sm-regular;
  line-height: $line-height-sm;

  @include media(">=small-phone", "<tablet") {
    font-size: $font-md-regular;
    line-height: $line-height-sm;
  }

  @include media(">=tablet") {
    line-height: $line-height;
    font-size: $font-regular;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

::selection {
  background-color: #000;
  color: #fff;
}

.t-red {
  color: $color-red;
}

.t-uc {
  text-transform: uppercase;
}

.t-paragraphs {
  a {
    display: inline-block;
  }

  p {
    margin: #{$line-height}em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:not(.t-paragraphs--red-links) {
    a:not(.t-red) {
      &:hover {
        color: $color-red;
      }
    }

    a.t-red {
      &:hover {
        color: #000;
      }
    }
  }

  &.t-paragraphs--red-links {
    a {
      color: $color-red;

      &:hover {
        color: #000;
      }
    }
  }
}
.t-sm-xl {
  @include media("<small-phone") {
    font-size: 35px;
  }
  @include media(">=small-phone", "<desktop") {
    font-size: 42px;
  }
  @include media("<desktop") {
    letter-spacing: 0.025em;
    margin-bottom: 1.3333rem;
  }
}