.lazyload--fade {
  transition: opacity 350ms;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }

  &.lazyloaded {
    opacity: 1;
  }
}

.embed-snippet {
  display: none;
}

.hide-on-mobile {
  @include media("<desktop") {
    display: none !important;
  }
}

.hide-on-desktop {
  @include media(">=desktop") {
    display: none !important;
  }
}