.section-wrap {
  @include media(">=desktop") {
    display: flex;
  }
}

.page-section {
  @include media(">=desktop") {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-height: calc(100vh - #{$header-height} - #{$footer-height} + 1px);
  }
}

.page-section-divider {
  width: 100%;
  border-bottom: 1px solid #000;
}

.page-section__expand {
  flex: 0 0 auto;

  @include media(">=desktop") {
    flex: 1 1 auto;
  }
}

.page-section__content {
  @include media(">=desktop") {
    border-top: 1px solid #000;
  }

  @include media("<desktop") {
    flex: 1 0 auto;
    display: flex;
  }
}