.cols {
  width: 100%;
  margin: 0 auto;

  @include media(">=desktop") {
    display: flex;
    justify-content: center;
  }

  @include media("<desktop") {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    .col--50:first-child {
      flex: 1 0 auto;
    }
  }
}

.cols--reverse {
  @include media(">=desktop") {
    flex-direction: row-reverse;
  }
}

.col--50 {
  &:not(.col--pad-none) {
    padding: ($margin-sm-regular - 3px) $margin-sm-regular ($margin-sm-regular - 8px);
  }

  @include media(">=tablet") {
    &:not(.col--pad-none) {
      padding: ($margin-lg-regular - 5px) $margin-lg-regular ($margin-lg-regular - 10px);
    }
  }

  @include media(">=desktop") {
    flex: 1 0 auto;
    width: 50%;
  }
}

.cols:not(.cols--reverse) {
  .col--50 + .col--50 {
    @include media("<desktop") {
      border-top: 1px solid #000;
    }

    @include media(">=desktop") {
      border-left: 1px solid #000;
    }
  }
}

.cols.cols--borderless {
  .col--50 + .col--50 {
    @include media("<desktop") {
      border-top: 0;
    }
  }

  .col--50:first-child {
    @include media("<desktop") {
      padding-bottom: 0;
    }
  }
}

.cols--reverse {
  .col--50:last-child {
    @include media("<desktop") {
      border-top: 1px solid #000;
    }
  }

  .col--50:first-child {
    @include media(">=desktop") {
      border-left: 1px solid #000;
    }
  }
}

.col--max-w {
  @include media(">=desktop") {
    max-width: 440px;
  }
}

.col--flex-end {
  @include media(">=desktop") {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.col--flex-split {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.col--flex-stretch {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}