.intro {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .site-title {
    cursor: default;
  }
}

.intro__choices {
  display: flex;
  flex: 1 0 auto;
  position: relative;

  @include media("<small-desktop") {
    flex-direction: column;
  }
}

.intro__choice-single {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2.5em;

  &:first-child {
    animation: revealTop 1500ms;
    animation-fill-mode: both;
    transform-origin: 0 0;
  }

  &:last-child {
    animation: revealBottom 1500ms; 
    animation-fill-mode: both;
    transform-origin: 0 0;
  }

  @include media("<small-desktop") {
    padding: 19px;
    height: 50%;
    flex: 1 0 50%;
  }

  @include media(">=small-desktop") {
    width: 50%;
    font-size: 3.5em;
  }
}

.divider {
  position: absolute;
  background-color: #000;
  animation: dividerGrowY 1500ms; 
  animation-fill-mode: both;
  transform-origin: 0 0;

  @include media(">=small-desktop") {
    top: 0;
    left: 50%;
    height: 100%;
    width: 1px;
  }

  @include media("<small-desktop") {
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    animation-name: dividerGrowX;
  }
}

@keyframes dividerGrowY {
  0% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dividerGrowX {
  0% {
    transform: scale(0, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes revealTop {
  0% {
    transform: translateY(-100%)
  }
  100% {
    transform: translateY(0%)
  }
}

@keyframes revealBottom {
  0% {
    transform: translateY(100%)
  }
  100% {
    transform: translateY(0%)
  }
}


@keyframes revealLeft {
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateY(0%)
  }
}

@keyframes revealRight {
  0% {
    transform: translateX(100%)
  }
  100% {
    transform: translateY(0%)
  }
}