.section-calendar {
  position: relative;

  @include media("<desktop") {
    min-height: auto !important;
    content: '' !important;
  }
}

.section-calendar__title {
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.section-calendar__title {
  @include media(">=small-desktop") {
    margin-left: -63px;
  }
}

.event-item {
  display: block;

  &:hover {
    .t-red {
      color: #000;
    }
  }

  @include media(">=tablet", "<desktop") {
    &:not(:last-child) {
      margin-bottom: #{$line-height}em;
    }
  }

  @include media("<tablet") {
    &:not(:last-child) {
      margin-bottom: #{$line-height-sm}em;
    }
  }

  @include media(">=desktop") {
    margin-top: #{$line-height}em;
  }
}

.event-item__title {
  text-transform: uppercase;
}

.event-detail__single {
  display: none;
  padding: ($margin-sm-regular - 3px) $margin-sm-regular ($margin-sm-regular - 8px);

  @include media(">=desktop") {
    padding: ($margin-lg-regular - 5px) $margin-lg-regular ($margin-lg-regular - 10px);
  }

  &.is-active {
    display: block;
  }
}

.event-detail__title {
  @include media(">=tablet") {
    margin-bottom: #{$line-height}em;
  }

  @include media("<tablet") {
    margin-bottom: #{$line-height-sm}em;
  }
}

.event-detail__date,
.event-detail__thumb {
  @include media(">=tablet") {
    margin: #{$line-height}em 0;
  }

  @include media("<tablet") {
    margin: #{$line-height-sm}em 0;
  }
}

.event-detail__book {
  @include media(">=tablet") {
    margin-top: #{$line-height}em; 
  }

  @include media("<tablet") {
    margin-top: #{$line-height-sm}em; 
  }

  &:hover .t-red {
    color: #000;
  }
}

.section-calendar__cover {
  position: absolute;
  top: 0;
  width: 50%;
  bottom: 0;
  right: -1px;
  display: flex;

  &.is-hidden {
    display: none;
  }

  @include media("<desktop") {
    display: none !important;
  }
}