ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button,
input,
select,
textarea{
  appearance: none;
  border: 0;
  padding: 0;
  background: none;
  border-radius: 0;
  line-height: inherit;
  font: inherit;
  resize: none;

  &:active {
    color: inherit;
  }

  &:focus {
    outline: 0;
  }
}

figure {
  margin: 0;
  padding: 0;
}