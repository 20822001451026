.section-info__group:not(:last-child) {
  @include media(">=tablet") {
    margin-bottom: #{$line-height}em;
  }

  @include media("<tablet") {
    margin-bottom: #{$line-height-sm}em;
  }
}

.section-info__top,
.section-info__bottom {
  padding: ($margin-sm-regular - 3px) $margin-sm-regular ($margin-sm-regular - 8px);

  @include media(">=desktop") {
    padding: ($margin-lg-regular - 5px) $margin-lg-regular ($margin-lg-regular - 10px);
  }
}

.section-info__bottom {
  border-top: 1px solid #000;
}

.section-info__contact,
.section-info__group {
  @include media(">=desktop") {
    max-width: 440px;
  }
}

.section-info__contact {
  margin-bottom: $margin-lg-regular;
}

.section-info  {
  a {
    display: inline-block;
  }
  
  a:hover {
    color: $color-red;
  }
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $margin-lg-regular;

  li {
    margin: 0 10px 0 0;
  }
}

.section-info__credits {
  font-size: $font-sm-small;

  @include media(">=desktop") {
    font-size: $font-small;
  }
}