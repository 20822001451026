.slideshow-placeholder {
  flex: 1 1 auto;  
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 180px;

  &[data-slideshow-trigger] {
    cursor: pointer;
  }

  @include media("<desktop") {
    &[data-slideshow-trigger] {
      display: none;
    }
  }
}

.slideshow,
.slideshow-placeholder {
  @include media("<desktop") {
    flex: 1 1 auto; 
    height: 65vw;
    min-height: 200px;
  }
}

.slideshow {
  width: 100%;
  z-index: 100;
  background-color: #fff;

  @include media ("<desktop") {
    position: relative;
  }

  @include media(">=desktop") {
    position: fixed;
    top: $header-height;
    left: 0;
    bottom: $footer-height;
    display: none;

    &.is-active {
      display: block;
    }
  }

  @media (hover: hover) {
    .slideshow__button {
      display: none;
    }

    .slideshow__wrap {
      &:not(.is-prev) {
        .flickity-viewport,
        .flickity-viewport.is-pointer-down {
          cursor: e-resize;
          cursor: url(../img/cursor-next.png) 17 27,e-resize;
          // cursor: url(../img/cursor-next.svg) 17 27,url(../img/cursor-next.png) 17 27,e-resize;
          cursor: -webkit-image-set(url(../img/cursor-next.png) 1x,url(../img/cursor-next@2x.png) 2x) 17 27,e-resize;
        }
      }

      &.is-prev {
        .flickity-viewport,
        .flickity-viewport.is-pointer-down {
          cursor: w-resize;
          cursor: url(../img/cursor-prev.png) 17 27,w-resize;
          // cursor: url(../img/cursor-prev.svg) 17 27,url(../img/cursor-prev.png) 17 27,w-resize;
          cursor: -webkit-image-set(url(../img/cursor-prev.png) 1x,url(../img/cursor-prev@2x.png) 2x) 17 27,w-resize;
        }
      }
    }
  }
}

.slideshow__wrap {
  position: relative;
  height: 100%;
  width: 100%;
}

.slideshow__button {
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 16px;
  transform: translateY(-50%);

  @include media(">=tablet") {
    width: 32px;
  }

  &.slideshow__button--prev {
    left: $margin-sm-regular;

    @include media(">=tablet") {
      left: $margin-lg-regular;
    }
  }

  &.slideshow__button--next {
    right: $margin-sm-regular;

    @include media(">=tablet") {
      right: $margin-lg-regular;
    }
  }
}

.slideshow__cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 32px;
  height: 54px;
  margin-top: -27px;
  margin-left: -16px;
  cursor: none;
  pointer-events: none;

  @include media ("<desktop") {
    display: none !important;
  }

  .touchevents & {
    display: none !important;
  }

  &.is-hidden {
    display: none !important;
  }

  &:not(.is-prev) {
    .cursor-prev {
      display: none;
    }
    .cursor-next {
      display: block;
    }
  }

  &.is-prev {
    .cursor-prev {
      display: block;
    }
    .cursor-next {
      display: none;
    }
  }
}

.slideshow__slide {
  background-color: #fff;
}

.slideshow__slide,
.flickity-viewport {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slideshow__btn {
  position: absolute;
  top: 0;
  z-index: 200;
  width: 58px;
  height: 100%;
  padding: $margin-sm-regular;
  cursor: pointer;

  @include media(">=desktop") {
    width: 80px;
    padding: $margin-lg-regular;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform 250ms;
  }
}

.slideshow__btn--next {
  right: 0;
}

.slideshow__btn--prev {
  left: 0;
}

.no-touchevents {
  .slideshow__btn--next {
    right: 0;

    &:hover {
      svg {
        transform: translateX(50%);   
      }
    }
  }

  .slideshow__btn--prev {
    left: 0;

    &:hover {
      svg {
        transform: translateX(-50%);   
      }
    }
  }
}

.slideshow__close {
  position: absolute;
  top: $margin-lg-regular;
  right: $margin-lg-regular;
  width: 38px;
  height: 38px;
  z-index: 100;
  cursor: pointer;
  transition: opacity 350ms;

  @include media ("<desktop") {
    display: none !important;
  }

  &:hover {
    opacity: 0.5;

    svg {
      transform: scale(0.9);
    }
  }

  svg {
    display: block;
    transition: all 350ms;
  }
}